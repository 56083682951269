import parseSRT from 'parse-srt';
import React, { RefObject, useEffect, useState } from 'react';

interface SRTViewerProps {
	srtText: string;
	audioRef: RefObject<HTMLAudioElement | null>;
	completeSubtitle: string;
	hasSrt: boolean;
}

interface Subtitle {
	start: number;
	end: number;
	text: string;
}

const SRTViewer = ({ srtText, audioRef, completeSubtitle, hasSrt }: SRTViewerProps) => {
	const completeRef = React.useRef<HTMLSpanElement>(null);
	const [showSubtitle, setShowSubtitle] = useState(true);
	const [subtitles, setSubtitles] = useState<Subtitle[]>([]);
	const [currentSubtitle, setCurrentSubtitle] = useState('');

	useEffect(() => {
		if (hasSrt && srtText) {
			const parsedSubtitles = parseSRT(srtText);
			setSubtitles(parsedSubtitles);
		}
	}, [srtText, hasSrt]);

	useEffect(() => {
		const checkSubtitle = () => {
			const currentTime = audioRef.current?.currentTime || 0;
			if (currentTime === audioRef.current?.duration) setShowSubtitle(false);
			else setShowSubtitle(true);

			if (hasSrt && subtitles.length > 0) {
				const subtitle = subtitles.find((sub) => currentTime >= sub.start && currentTime <= sub.end);
				setCurrentSubtitle(subtitle ? subtitle.text : '');
			}
		};

		const audio = audioRef.current;
		if (audio) {
			audio.addEventListener('timeupdate', checkSubtitle);
			return () => {
				audio.removeEventListener('timeupdate', checkSubtitle);
			};
		}
	}, [audioRef, subtitles, hasSrt]);

	const renderCompleteSubtitle = () => <>{completeSubtitle}</>;

	const highlightedText = () => {
		const currentTime = audioRef.current?.currentTime || 0;
		const duration = audioRef.current?.duration || 0;
		const words = completeSubtitle.split(' ');
		const wordsPerGroup = 15;
		const currentSubtitleWords = currentSubtitle.split(' ');

		const proportionOfTime = currentTime / duration;
		const totalGroups = Math.ceil(words.length / wordsPerGroup);
		let groupToShow = Math.floor(totalGroups * proportionOfTime);

		groupToShow = Math.max(0, Math.min(totalGroups - 1, groupToShow));

		const groupStartIndex = groupToShow * wordsPerGroup;
		const groupEndIndex = Math.min(words.length, groupStartIndex + wordsPerGroup);

		let currentSubtitleIndex = 0;

		const newText = words.slice(groupStartIndex, groupEndIndex).map((word, index) => {
			const shouldHighlight =
				word === currentSubtitleWords[currentSubtitleIndex] && currentSubtitleIndex < currentSubtitleWords.length;
			if (shouldHighlight) currentSubtitleIndex++;

			return (
				<span
					key={index + groupStartIndex}
					style={{
						color: shouldHighlight ? 'yellow' : 'white',
					}}
				>
					{word}
				</span>
			);
		});

		return <>{newText.reduce((prev: any, curr: any) => [prev, ' ', curr], [])}</>;
	};

	return (
		showSubtitle && (
			<div className="w-full absolute bottom-10 z-50">
				<span
					className={`text-white text-20 font-beba uppercase text-center absolute left-0 bottom-20 ${
						completeSubtitle.length > 40 ? 'text-14' : ''
					} w-full px-20 bg-black/40`}
					ref={completeRef}
				>
					{hasSrt ? highlightedText() : renderCompleteSubtitle()}
				</span>
			</div>
		)
	);
};

export default SRTViewer;
