import 'turn.js';

import { ArrowBack, BookOutlined, StarBorderOutlined } from '@mui/icons-material';
import { Alert, Button } from '@mui/material';
import { Box } from '@mui/system';
import $ from 'jquery';
import { memo, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { LoadingPage } from '../../components/loading-page';
import { WrapperPage } from '../../components/wrapper-page';
import { useDetectZoom } from '../../hooks/useDetectZoom';
import { useUser } from '../../hooks/useUser';
import { PageResponse } from '../../interfaces/library';
import { BooksServices } from '../../services/books';
import SRTViewer from './components/SRTViewer';
import Turn from './components/Turn';
import FinalQuestions from './final-questions';
import FinalScreen from './final-screen';
import PlayOptions from './play-options';

declare global {
	interface JQueryStatic {
		turn: (options: any, value?: any) => any;
		isTouch: boolean;
	}
	interface JQuery {
		turn: (options: any, value?: any) => any;
		isTouch: boolean;
	}
}

interface State {
	loading: boolean;
	error: boolean;
}

interface PlayOptions {
	autoPlay: boolean;
	audioVoice: boolean;
	backgroundMusic: boolean;
	fullScreen: boolean;
	subtitles: boolean;
}

const defaultState: State = {
	loading: false,
	error: false,
};

const initialPlayOptions: PlayOptions = {
	autoPlay: true,
	audioVoice: true,
	backgroundMusic: true,
	fullScreen: false,
	subtitles: true,
};

const BookView = memo(() => {
	const id = useParams<{ id: string }>().id;
	const { studentSelected, courseSelected } = useUser();
	const navigate = useNavigate();

	const [viewQuestions, setViewQuestions] = useState<boolean>(false);
	const [pagesData, setPagesData] = useState<PageResponse>();
	const [state, setState] = useState<State>(defaultState);
	const [pageActive, setPageActive] = useState<number>(0);
	const [visitedPages, setVisitedPages] = useState<number[]>([]);
	const [playOptions, setPlayOptions] = useState<PlayOptions>(initialPlayOptions);
	const [loadingQuestions, setLoadingQuestions] = useState<boolean>(true);
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const audioBackgroundRef = useRef<HTMLAudioElement | null>(null);

	const { currentZoom, previousZoom } = useDetectZoom();

	const options = {
		width: window.innerWidth * 0.5,
		height: window.innerHeight * 0.6,
		display: 'single',
		range: 30,
		autoCenter: true,
		acceleration: true,
		elevation: 50,
		gradients: !$.isTouch,
		pages: pagesData?.pages.length || 0,
		when: {
			turned: function (_e: any, page: number) {
				setPageActive(page - 1);
			},
		},
		//Change page fnct
		turn: function (_: any, page: number) {
			$('.magazine').turn('page', page);
		},
	};

	useEffect(() => {
		const getBook = async () => {
			setState((prev) => ({ ...prev, loading: true }));
			try {
				if (!id) navigate('/books');
				const data = await BooksServices.getPages(id as string);
				setPagesData(data);
				setState((prev) => ({ ...prev, error: false }));
			} catch (error) {
				setState((prev) => ({ ...prev, error: true }));
				setTimeout(() => {
					navigate('/library');
				}, 1500);
			} finally {
				setState((prev) => ({ ...prev, loading: false }));
			}
		};
		getBook();
	}, []);

	const handleSelectPage = async (index: number) => {
		setPageActive(index);
		$('.magazine').turn('page', index + 1);
		setVisitedPages((prev) => [...prev, index]);
		if (new Set(visitedPages).size === (pagesData?.pages.length as number) - 1) {
			const idToSend = studentSelected ? studentSelected._uid : courseSelected?._uid;
			await BooksServices.sendEvents(
				idToSend as string,
				id as string,
				[
					{
						type: 'read_book',
					},
				],
				studentSelected?.courseId || courseSelected?._uid,
				studentSelected?.schoolId,
			);
		}
	};

	useEffect(() => {
		setVisitedPages((prev) => [...prev, pageActive]);
	}, [pageActive]);

	useEffect(() => {
		const audio = document.getElementById('audio_page') as HTMLAudioElement;
		if (audio) {
			audio.src = pagesData?.pages[pageActive]?.audio?.url || '';
			audio.pause();
			audio.currentTime = 0;
			audio.volume = playOptions.audioVoice ? 1 : 0;
			audio.play();
		}

		const audioBG = document.getElementById('audio_background') as HTMLAudioElement;
		if (audioBG && audioBG.src !== pagesData?.background_audio?.url) {
			audioBG.src = pagesData?.background_audio?.url || '';
			audioBG.pause();
			audioBG.currentTime = 0;
			audioBG.volume = playOptions.backgroundMusic ? 1 : 0;
			audioBG.play();
		}
	}, [pageActive, pagesData]);

	useEffect(() => {
		if (playOptions.autoPlay && pagesData?.pages) {
			if (audioRef.current?.currentTime === audioRef.current?.duration && pagesData?.pages?.length > 1)
				handleNextPage();
		}
		if (playOptions.fullScreen) {
			document.body.classList.add('full-screen');
		} else {
			document.body.classList.remove('full-screen');
		}
		const audioBG = document.getElementById('audio_background') as HTMLAudioElement;
		const audioPage = document.getElementById('audio_page') as HTMLAudioElement;
		if (audioBG && audioPage) {
			audioBG.volume = playOptions.backgroundMusic ? 1 : 0;
			audioPage.volume = playOptions.audioVoice ? 1 : 0;
		}
	}, [playOptions]);

	const handleNextPage = () => {
		handleSelectPage(pageActive + 1);
	};

	useEffect(() => {
		setPlayOptions((prev) => ({ ...prev, fullScreen: previousZoom < currentZoom }));
	}, [currentZoom]);

	return (
		<WrapperPage title="Inicio">
			{state.error && (
				<Alert severity="error" className="w-full">
					No se pudo cargar el libro
				</Alert>
			)}
			{!pagesData ? (
				<div className="sticky top-0 left-0 h-[89vh] w-screen">
					<LoadingPage />
				</div>
			) : (
				<>
					{loadingQuestions && viewQuestions && (
						<div className="sticky top-0 left-0 h-[89vh] w-screen">
							<LoadingPage />
						</div>
					)}
					<div
						className={`mt-10 relative overflow-hidden flex flex-row justify-center items-center ${
							playOptions.fullScreen ? 'absolute -top-[15vh] scale-110 left-0' : ''
						} transition-all duration-300`}
						style={{
							height: playOptions.fullScreen ? '100vh' : 'auto',
						}}
					>
						<Box className="rounded-t-lg rounded-b-3xl min-w-[30em] w-[62vw] mx-auto relative min-h-[80vh] h-full bg-[#5b4c69] p-4 shadow-2xl z-9999">
							{' '}
							<Box className="flex flex-col justify-between items-center h-full gap-10 p-2">
								<Box
									className={`h-full w-full rounded-lg overflow-visible relative flex flex-row justify-center items-center ${
										viewQuestions ? 'p-0' : 'p-20 bg-[#f2d243]/60 '
									}`}
									sx={{
										background: viewQuestions
											? `url(/images/cloudsTop.png), linear-gradient(to top, #63b3ed, #6ac3fc)`
											: `null`,
										backgroundSize: '150%',
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'left top',
										backgroundBlendMode: 'luminosity',
									}}
								>
									<audio
										ref={audioRef}
										id="audio_page"
										controls
										autoPlay
										hidden
										onEnded={() => {
											if (playOptions.autoPlay) handleNextPage();
										}}
									>
										<source src={pagesData?.pages[pageActive]?.audio?.url} type="audio/mpeg" />
										Your browser does not support the audio element.
									</audio>

									<audio ref={audioBackgroundRef} id="audio_background" controls autoPlay hidden loop>
										<source src={pagesData?.background_audio?.url} type="audio/mpeg" />
										Your browser does not support the audio element.
									</audio>

									{pagesData.pages && !viewQuestions && (
										<Turn options={options} className="magazine">
											{pagesData.pages.map((page, index) => (
												<div key={index} className="page">
													<img src={page.image.url} alt="" className="w-full h-full object-contain" />
												</div>
											))}
											<FinalScreen
												visitedPages={visitedPages}
												pagesData={pagesData.pages}
												setPageActive={setPageActive}
												setViewQuestions={setViewQuestions}
												turn={options.turn}
											/>
										</Turn>
									)}

									{!viewQuestions && playOptions.subtitles && (
										<SRTViewer
											audioRef={audioRef}
											srtText={pagesData?.pages[pageActive]?.srtFile?.file || ''}
											completeSubtitle={pagesData?.pages[pageActive]?.subtitle || ''}
											hasSrt={Boolean(pagesData?.pages[pageActive]?.srtFile?.file)}
										/>
									)}

									{viewQuestions && (
										<Box className="w-full h-full">
											<img
												src="/images/eyeIcon.png"
												alt="eyeIcon"
												className="absolute -top-10 left-0 right-0 mx-auto w-[60px] z-50 h-[60px] object-contain"
											/>
											<FinalQuestions setLoadingQuestions={setLoadingQuestions} />
										</Box>
									)}
								</Box>

								<Box
									className={`flex flex-row gap-5 items-center justify-center relative overflow-y-hidden py-2 w-full ${
										pagesData.pages.length > 13 ? 'overflow-x-auto w-[92%]' : ''
									}`}
								>
									{!viewQuestions ? (
										<>
											{pagesData?.pages.map((page, index) => (
												<Box
													key={page.uid}
													className={`cursor-pointer flex flex-col items-center justify-center gap-5 border-white border-[4px] bg-[#4dbcdc] rounded-full ${
														index === pageActive ? 'shadow-[inset_0px_0px_15px_rgba(0,0,0,0.7)] overflow-hidden' : ''
													}
												${pagesData.pages.length > 13 ? 'min-h-[50px] min-w-[50px]' : 'min-h-[45px] min-w-[45px]'}`}
													onClick={() => handleSelectPage(index)}
												>
													{index === 0 && <BookOutlined className="w-14 h-14 text-white" />}
													{index !== 0 && <p className="text-10 text-white font-medium">{index}</p>}
												</Box>
											))}

											<Box
												className={`cursor-pointer flex flex-col items-center justify-center gap-5 border-white border-[4px] bg-[#4dbcdc] rounded-full ${
													pagesData?.pages.length === pageActive
														? 'shadow-[inset_0px_0px_15px_rgba(0,0,0,0.7)] overflow-hidden'
														: ''
												} ${pagesData.pages.length > 13 ? 'min-h-[50px] min-w-[50px]' : 'min-h-[45px] min-w-[45px]'}`}
												onClick={() => handleSelectPage(pagesData?.pages.length as number)}
											>
												<StarBorderOutlined className="w-14 h-14 text-white" />
											</Box>
										</>
									) : (
										<Button
											sx={{
												width: 200,
												margin: 'auto',
												backgroundColor: 'blue',
												'&:hover': {
													backgroundColor: '#4dbcdc',
													boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
												},
												border: 4,
												borderColor: 'white',
												borderRadius: '10px',
											}}
											className="bg-blue-400 py-6"
											variant="contained"
											onClick={() => {
												setViewQuestions(false);
												handleSelectPage(0);
											}}
										>
											<ArrowBack className="mr-4 w-14" />
											Volver al libro
										</Button>
									)}
								</Box>
							</Box>
							<PlayOptions playOptions={playOptions} setPlayOptions={setPlayOptions} />
						</Box>
					</div>
				</>
			)}
		</WrapperPage>
	);
});

BookView.displayName = 'BookView';

export default BookView;
